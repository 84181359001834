:root {
    --color-primary: #97c93e;
    --color-primary-300: #97c93e6b;
    --text-color-primary: #7ebc11;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
   .min-h-50px {
       min-height: 250px;
   }
   a {
       color: #6f7275 !important;
   }
   .min-h-528px {
       height: 534px;
   }
   .min-h-8 {
       min-height: 8rem;
    }
   .profile-dropdown ul {
       margin-top: -13rem;
   }
   .bdpw {
    padding: 2px 8px;
    font-weight: 700;
   }
   .bg-gray-sw button{
       background-color: #f4f5f7;
   }
   .pd-2 {
       padding: 2px;
   }
   .bg-primary {
       background-color: var(--color-primary);
   }
   .rounded-mdi {
       border-radius: 0.375rem !important;
   }
   .vh-83 {
       height: 82.5vh !important;
   }
   .w-310 {
       width: 310px !important;
       min-width: 310px !important;
   }
   .w-88px {
    width: 88px !important;
    min-width: 88px !important;
   }
   .w-89px {
    width: 89px !important;
    min-width: 89px !important;
   }
   .vh-79 {
    height: 78vh !important;
   }
   .vh-50 {
    height: 56vh !important;
   }
   .py-7i {
    padding-bottom: 7px !important;
    padding-top: 7px !important;
   }
   .fileup .iciELI {
       min-width: 280px !important;
       max-width: 100% !important;
   }
   .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 1.25em;
        width: 100%;
        padding: 16px;
        text-align: center;
        font-weight: 700;
        color: #888;
        background-color: #f1f1f1;
        border: 1.5px dashed #666;
        border-radius: 8px;
        display: inline-block;
    }
    
    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: #f1f1f1;
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
   .css-1s2u09g-control {
    border-radius: 0.375rem !important;
   }
   .bg-opacity-25i {
    --tw-bg-opacity: 0.25 !important;
   }
   .bg-primary-300 {
       background-color: var(--color-primary-300);
   }
   .text-primary {
       color: var(--text-color-primary);
    }
    .border-primary {
       color: var(--text-color-primary) !important;
    }
    .tabs-checkin .react-tabs__tab--selected {
        border-color: #97c93e !important;
        border-bottom-width: 2px !important;
        background: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-bottom: 10px;
    }
   .bg-purple-600 {
       background-color: var(--color-primary) !important;
   }
   .tablePlace .InovuaReactDataGrid {
        border-top-left-radius: 0.75rem !important;
        border-top-right-radius: 0.75rem !important;
        border-bottom-left-radius: 0.75rem !important;
        border-bottom-right-radius: 0.75rem !important;
   }
   .tablePlace .InovuaReactDataGrid__header {
        border-top-left-radius: 0.75rem !important;
        border-top-right-radius: 0.75rem !important;
        color: #6f7275;
        text-transform: uppercase;
        font-weight: 500 !important;
   }
   .tablePlace .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content {
        font-weight: 500 !important;
   }
   .tablePlace .InovuaReactDataGrid__body {
        border-top-left-radius: 0.75rem !important;
        border-top-right-radius: 0.75rem !important;
   }
   .tablePlace .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--odd {
       background-color: #fff !important;
   }
   .tablePlace .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--first .InovuaReactDataGrid__cell {
       border-left: none !important;
       border-right: none !important;
       border-top: 1px solid transparent !important;
       border-bottom: 1px solid #e4e7eb !important;
   }
   .tablePlace .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell {
        border-bottom: 1px solid #e4e7eb !important;
        border-left: none !important;
        border-right: none !important;
    }
   .tablePlace .inovua-react-toolkit-toolbar {
        border-bottom-left-radius: 0.75rem !important;
        border-bottom-right-radius: 0.75rem !important;
    }
    .tablePlace .InovuaReactDataGrid__column-header__resize-wrapper {
        border-left: none !important;
        border-right: none !important;
        background-color: #f9fafb !important;
    }
    .inovua-react-pagination-toolbar.inovua-react-pagination-toolbar--theme-default-light {
        background-color: #f9fafb !important;
    }
}