.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscurecido */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Z-index del modal (ajusta según tus necesidades) */
}

.rnc__base {
  top: 0;
  z-index: 777777;
}

